import axios from 'axios';
import store from '../store';

let domain =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:8000'
    : 'https://i-got-standards-bro-backend.onrender.com';

let apiClient = axios.create({
  baseURL: `${domain}/api/v1`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// bypasses the need for catch in every axios call
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // this is a security measure to automatically log out fake users with invalid token
    if (error.response.status === 401) {
      store.dispatch('logout');
      return Promise.reject(error);
    }

    // if the axios call has an errorHandle config option, don't intercept it and follow its own catch
    if (
      Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }

    // otherwise show the detail of the error in a dialog box
    let message;
    if ('detail' in error.response.data) {
      message = error.response.data.detail;
    } else {
      message = error.message;
    }

    // calls the vuex function to show the full screen message
    if (error.response) {
      store.dispatch('showError', message);
    }
  }
);

export default {
  getProbability(details) {
    return apiClient.post('/query/', details);
  },

  getTraffic() {
    return apiClient.get('/query/traffic/');
  },
};
