export default {
  cmToImperial(value) {
    let imperialLength = value * 0.032808399;
    if (imperialLength < 4.0000001) {
      return 'Any';
    }
    let feet = Math.trunc(imperialLength + 0.0000001);
    let inches = Math.round((imperialLength - feet) * 12);
    if (inches == 0) {
      return `${feet}'`;
    }
    return `${feet}' ${inches}"`;
  },
};
