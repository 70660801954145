<template>
  <!-- loading screen -->
  <div class="results-loading-screen" v-show="isLoading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="container">
    <div class="results-container">
      <!-- Ezoic - results_top_page - top_of_page -->
      <div class="u-text-align-center" id="ezoic-pub-ad-placeholder-103"/>
      <!-- End Ezoic - results_top_page - top_of_page -->

      <div class="heading-secondary u-margin-top-medium u-text-align-center">
        {{ title }}
      </div>

      <div class="box paragraph">
        <ul>
          <li :class="{ greyed: !details.exclude_married }">
            {{ marriedText }}
          </li>
          <li :class="{ greyed: !details.race }">{{ raceText }}</li>
          <li :class="{ greyed: !details.min_height }">{{ heightText }}</li>
          <li :class="{ greyed: !details.exclude_obese }">{{ obeseText }}</li>
          <li :class="{ greyed: !details.min_income }">{{ incomeText }}</li>
        </ul>
      </div>

      <!-- probability section -->
      <div v-if="probabilityPerc !== null">
        <div class="heading-secondary u-margin-top-medium u-text-align-center">
          Probability
        </div>
        <div class="box">
          <!-- visualization of the male population -->
          <div class="population-visualizer">
            <svg viewBox="0 0 100 53">
              <g v-for="(guy, idx) in guys" v-bind:key="idx">
                <circle
                    :cx="guy.x"
                    :cy="guy.y"
                    :r="guyRadius"
                    :fill="guyValueToColor(guy.value)"
                />
              </g>
            </svg>
          </div>
          <div class="paragraph">
            According to
            <router-link class="button-text-accent" :to="{ name: 'Stats' }"
            >statistical data
            </router-link>
            , the probability a guy of the U.S. male population ages
            <span class="paragraph-bold">{{ details.min_age }}</span>
            to <span class="paragraph-bold">{{ details.max_age }}</span> meets
            your standards is
          </div>

          <!-- percentage -->
          <div class="u-margin-top-medium">
            <div class="result-number result-number--big u-text-align-center">
              {{ probabilityPerc }}%
            </div>

            <div class="paragraph u-text-align-center" v-if="details.race">
              that is
              <span class="result-number">{{ probabilityInRacePerc }}%</span>
              of all <span class="paragraph-bold">{{ raceText }}</span> men in
              that age range
            </div>

            <div
                class="paragraph u-text-align-center u-margin-top-medium warning"
                v-if="heightWarning"
            >
              Warning: the sample does not contain enough individuals at the
              selected height within the specified age range, this result might
              not properly reflect the real percentage value.
            </div>
          </div>
        </div>
      </div>

      <!-- delusion score section -->
      <div v-if="delusionScore !== null">
        <div class="heading-secondary u-margin-top-medium u-text-align-center">
          Delusion score
        </div>

        <div class="box">
          <div class="score-flex">
            <img
                class="score-img"
                :src="require(`@/assets/score_item_${litterImageSrc(1)}.svg`)"
                alt="*"
            />
            <img
                class="score-img"
                :src="require(`@/assets/score_item_${litterImageSrc(2)}.svg`)"
                alt="*"
            />
            <img
                class="score-img"
                :src="require(`@/assets/score_item_${litterImageSrc(3)}.svg`)"
                alt="*"
            />
            <img
                class="score-img"
                :src="require(`@/assets/score_item_${litterImageSrc(4)}.svg`)"
                alt="*"
            />
            <img
                class="score-img"
                :src="require(`@/assets/score_item_${litterImageSrc(5)}.svg`)"
                alt="*"
            />
          </div>
          <div
              class="result-number result-number--big u-text-align-center u-margin-top-small"
          >
            {{ delusionScore }}/5
          </div>
          <div class="heading-tertiary result-number u-text-align-center">
            {{ delusionText }}
          </div>
        </div>

        <!-- Visit the store! -->
<!--        <div class="u-text-align-center u-margin-top-medium" :style="{display: 'flex', flexDirection: 'column', alignItems: 'center'}">-->
<!--          <a class="button-image"-->
<!--             href="https://igotstandards.shop/">-->
<!--            <div class="mug-button-container">-->
<!--              <img class="visit-store-img" :src="'mugs/mug_ds_' + Math.max(delusionScore, 1) + '.png'" alt="Store">-->
<!--              <div class="paragraph-bold visit-store-text">SHOP NOW!</div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
      </div>

      <!-- Ezoic - results_before_button - mid_content -->
      <div class="u-text-align-center" id="ezoic-pub-ad-placeholder-104"/>
      <!-- End Ezoic - results_before_button - mid_content -->

      <!-- New search -->
      <router-link
          class="button-accent home-button u-text-align-center u-margin-top-medium"
          :to="{ name: 'Calculator' }"
      >New search
      </router-link>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/api-service';
import conversions from '@/utils/conversions';
import arrays from '@/utils/arrays';
import ezoic from '@/utils/ezoic';

const totalGuys = 1000;
const guyLineCount = 40;
const guySpace = 100.0 / (guyLineCount + 0.5);
const guyRadius = guySpace * 0.85 * 0.5;
const guyGap = guySpace - guyRadius * 2.0;

export default {
  name: 'Results',

  data() {
    return {
      title: 'My ideal man',
      details: {
        min_age: 0,
        max_age: 200,
        exclude_married: false,
        race: 0,
        min_height: 0.0,
        exclude_obese: false,
        min_income: 0
      },

      probabilityPerc: null,
      probabilityInRacePerc: null,
      heightWarning: false,

      guys: null,
      guyRadius: guyRadius,
      guyGap: guyGap
    };
  },

  created() {
    // get all values from the url
    if (this.$route.query.title) this.title = this.$route.query.title;
    if (this.$route.query.minAge)
      this.details.min_age = parseInt(this.$route.query.minAge);
    if (this.$route.query.maxAge)
      this.details.max_age = parseInt(this.$route.query.maxAge);
    if (this.$route.query.race)
      if (this.$route.query.excludeMarried)
        this.details.exclude_married =
            this.$route.query.excludeMarried === 'true';
    this.details.race = parseInt(this.$route.query.race);
    if (this.$route.query.minHeight)
      this.details.min_height = parseFloat(this.$route.query.minHeight);
    if (this.$route.query.excludeObese)
      this.details.exclude_obese = this.$route.query.excludeObese === 'true';
    if (this.$route.query.minIncome)
      this.details.min_income = parseInt(this.$route.query.minIncome);

    // create the guy elements
    this.guys = [];
    for (let i = 0; i < totalGuys; i++) {
      let row = Math.floor(i / guyLineCount);
      let x =
          this.guyRadius +
          (row % 2) * (this.guyRadius + this.guyGap * 0.5) +
          (i % guyLineCount) * (2 * this.guyRadius + this.guyGap);
      let y = this.guyRadius + row * 2 * this.guyRadius;
      this.guys.push({ x: x, y: y, value: false });
    }
  },

  mounted() {
    // call the api
    this.compute();

    // update ezoic ads for this page
    ezoic.updateAds([103, 104]);
  },

  computed: {
    isLoading() {
      return this.probabilityPerc === null;
    },

    delusionScore() {
      if (this.probabilityPerc === null) return null;
      else if (this.probabilityPerc >= 90.0) return 0;
      else if (this.probabilityPerc >= 50.0) return 1;
      else if (this.probabilityPerc >= 10.0) return 2;
      else if (this.probabilityPerc >= 2.0) return 3;
      else if (this.probabilityPerc >= 0.1) return 4;
      else return 5;
    },

    delusionText() {
      if (this.delusionScore === 0) return 'Are you sure you are a woman?';
      else if (this.delusionScore === 1) return 'Easy to please';
      else if (this.delusionScore === 2) return 'Down to earth';
      else if (this.delusionScore === 3) return 'Aspiring cat lady';
      else if (this.delusionScore === 4) return 'Cat enthusiast';
      else if (this.delusionScore === 5)
        return 'You don\'t belong on this planet';
      else return '';
    },

    marriedText() {
      if (this.details.exclude_married) return 'not married';
      return 'any marital status';
    },

    raceText() {
      if (this.details.race === 1) return 'white';
      if (this.details.race === 2) return 'black';
      if (this.details.race === 3) return 'asian';

      return 'any race';
    },

    heightText() {
      if (this.details.min_height === 0) return 'any height';
      return `at least ${conversions.cmToImperial(
          this.details.min_height
      )} tall`;
    },

    obeseText() {
      if (this.details.exclude_obese) return 'not obese';
      return 'any weight';
    },

    incomeText() {
      if (this.details.min_income) {
        let val_fmt = this.details.min_income.toLocaleString();
        return `earning at least $${val_fmt} per year`;
      }

      return 'any income';
    }
  },

  methods: {
    litterImageSrc(element) {
      if (this.delusionScore < element) return 'off';
      else return 'on';
    },

    // set the number of decimal places depending on how small is the number
    ratioToPercString(ratio) {
      let val = ratio * 100.0;
      if (val < 0.01) return val.toFixed(4);
      if (val < 0.1) return val.toFixed(3);
      if (val < 1) return val.toFixed(2);
      return val.toFixed(1);
    },

    // give color to the chosen guys
    guyValueToColor(val) {
      if (val) {
        return '#f7fbfc';
      } else return '#f7fbfc40';
    },

    compute() {
      // store the values in vuex
      this.$store.dispatch('setCalcFormValues', this.details);

      // call the api
      apiService.getProbability(this.details).then((response) => {
        this.probabilityPerc = this.ratioToPercString(
            response.data['total_probability']
        );
        this.probabilityInRacePerc = this.ratioToPercString(
            response.data['total_probability_in_race']
        );
        this.heightWarning = response.data['height_probability_in_race'] === 0;

        // find out how many people it is in a group of 1000 guys (rounded to nearest int)
        let chosenCount = Number.parseInt(
            (response.data['total_probability'] * 1000.0).toFixed()
        );

        if (chosenCount > 0) {
          // create a range of 1000 ints
          let thousandRange = [];
          for (let i = 0; i < totalGuys; i++) {
            thousandRange.push(i);
          }
          // shuffle the elements
          arrays.shuffle(thousandRange);

          // turn on the first chosenCount guys
          for (let i = 0; i < chosenCount; i++) {
            this.guys[thousandRange[i]].value = true;
          }
        }

        // if it's in dev mode, log the results
        if (process.env.NODE_ENV === 'development') {
          console.log(response.data);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
// loading screen
.results-loading-screen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  font-size: $default-font-size;
  //border-radius: $border-radius-small;
  background-color: rgba($color-black, 0.5);
}

// percentage text
.result-number {
  color: $color-accent;

  &--big {
    font-family: $font-heading;
    font-weight: $font-weight-heading-regular;
    font-size: 5rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
}

.greyed {
  color: rgba($color-white, 0.5);
}

.results-container {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
}

// container that holds the score images
.score-flex {
  margin: 4rem auto 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.score-img {
  width: 18%;
  height: auto;
}

.home-button {
  padding: 2rem 3rem;
  width: 100%;
}

.warning {
  color: $color-error;
}

.population-visualizer {
  margin: 1rem 0 2rem 0;
}

</style>
