<template>
  <popup-message />
  <div class="main-layout">
    <router-view />
    <footer-menu />
  </div>
</template>

<script>
import PopupMessage from './components/PopupMessage';
import FooterMenu from '@/components/FooterMenu.vue';

export default {
  components: {
    PopupMessage,
    FooterMenu,
  },
};
</script>

<style lang="scss">
.main-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
</style>
