import { createStore } from 'vuex';

export default createStore({
  state: {
    alertType: 0,
    alertMessage: '',

    calcFormValues: null,
  },
  getters: {
    isAlertVisible: (state) => state.alertType !== 0,
    alertMessage: (state) => state.alertMessage,
    calcFormValues: (state) => state.calcFormValues,
  },
  mutations: {
    SET_INFO_MESSAGE(state, message) {
      state.alertMessage = message;
      state.alertType = 1;
    },
    SET_WARNING_MESSAGE(state, message) {
      state.alertMessage = message;
      state.alertType = 2;
    },
    SET_ERROR_MESSAGE(state, message) {
      state.alertMessage = message;
      state.alertType = 3;
    },
    DISMISS_ALERT(state) {
      state.alertMessage = '';
      state.alertType = 0;
    },
    SET_CALC_FORM_VALUES(state, values) {
      state.calcFormValues = values;
    },
  },
  actions: {
    showInfo(context, message) {
      context.commit('SET_INFO_MESSAGE', message);
    },
    showWarning(context, message) {
      context.commit('SET_WARNING_MESSAGE', message);
    },
    showError(context, message) {
      context.commit('SET_ERROR_MESSAGE', message);
    },
    dismissAlert(context) {
      context.commit('DISMISS_ALERT');
    },
    setCalcFormValues(context, values) {
      context.commit('SET_CALC_FORM_VALUES', values);
    },
  },
  modules: {},
});
