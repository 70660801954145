<template>
  <div class="popup-bg" v-if="isAlertVisible">
    <div class="message-container">
      <div class="paragraph">{{ alertMessage }}</div>
      <div class="button-accent message-button" @click="dismiss">OK</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PopupMessage',
  computed: {
    ...mapGetters(['isAlertVisible', 'alertMessage']),
  },
  methods: {
    dismiss() {
      this.$store.dispatch('dismissAlert');
    },
  },
};
</script>

<style scoped lang="scss">
.popup-bg {
  position: fixed;

  display: grid;
  justify-content: center;
  align-items: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.message-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 1.2rem;
  padding: 1.2rem;

  width: 40rem;
  min-height: 15rem;

  background-color: $color-grey-1;
  border-radius: $border-radius-small;
}

.message-button {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem 0;
}
</style>
