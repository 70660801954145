import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';

import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Results from '../views/Results.vue';
import Stats from '../views/Stats.vue';
import Privacy from '../views/Privacy.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/',
    name: 'Calculator',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  hashbang: false,
  routes,
  scrollBehavior: function (to) {
    // special treatment for the calculator link to make sure the anchor tag doesn't appear in the url
    if (to.name === 'Calculator') {
      return { el: '#calculator' };
    } else if (to.hash) {
      return { el: to.hash };
    } else {
      return { top: 0 };
    }
  },
});

trackRouter(router);

export default router;
