export default {
  updateAds(adIds) {
    window.ezstandalone = window.ezstandalone || {};
    window.ezstandalone.cmd = window.ezstandalone.cmd || [];

    // if the ezoic IDs are not defined, initialize them
    if (
      typeof window.ezstandalone.placeholders === 'undefined' ||
      !window.ezstandalone.enabled
    ) {
      window.ezstandalone.cmd.push(function () {
        window.ezstandalone.define(...adIds);
        window.ezstandalone.enable();

        window.ezstandalone.display();
      });
      if (process.env.NODE_ENV == 'development') {
        console.log('Ezoic started');
      }

      // if the ezoic IDs don't match with the page ads, update ezoic IDs
    } else if (
      JSON.stringify(window.ezstandalone.placeholders) !== JSON.stringify(adIds)
    ) {
      window.ezstandalone.cmd.push(function () {
        window.ezstandalone.define(...adIds);
        window.ezstandalone.refresh();
      });

      if (process.env.NODE_ENV == 'development') {
        console.log('Ezoic ads updated');
      }

      // otherwise do nothing
    } else {
      if (process.env.NODE_ENV == 'development') {
        console.log('Nothing to update');
      }
    }
  },
};
