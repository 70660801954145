<template>
  <div class="container">
    <div class="heading-primary u-margin-top-medium u-text-align-center">
      Stats
    </div>
    <div class="heading-secondary u-margin-top-medium u-text-align-center">
      Where is the data coming from?
    </div>
    <div class="box paragraph">
      <p>
        The <b>Female Delusion Calculator</b> processes statistical data from
        two sources.
      </p>
      <p>
        Income and marital status information is derived from the
        <b
          >2023 Annual Social and Economic Supplement (ASEC) of the Current
          Population Survey (CPS)</b
        >
        conducted by the <b>Census Bureau</b> of United States.
      </p>
      <p>
        Height and body mass index is derived from the
        <b
          >2017-2018 National Health and Nutrition Examination Survey
          (NHANES)</b
        >
        conducted by the
        <b>National Center for Health Statistics (NCHS)</b>. The 2017-2018 data
        was used instead of the most recent one due to suspension of survey
        operations in March 2020.
      </p>
    </div>
    <div class="heading-secondary u-margin-top-medium u-text-align-center">
      Exclude married
    </div>

    <div class="box paragraph">
      <p>What is the <b>exclude married</b> option about?</p>
      <p>The marital status options in the ASEC survey are:</p>
      <ol>
        <li>Married, spouse present</li>
        <li>Married, spouse absent</li>
        <li>Separated</li>
        <li>Divorced</li>
        <li>Widowed</li>
        <li>Never married/single</li>
        <li>Widowed or Divorced</li>
      </ol>
      By ticking the <b>exclude married</b> option you are filtering out the
      first two options from the sample data.
    </div>

    <div class="heading-secondary u-margin-top-medium u-text-align-center">
      Race
    </div>
    <div class="box paragraph">
      <p>
        The ASEC survey allows 28 different race options to chose from,
        including combinations of two or more races, for example
        White-Black-American Indian.
      </p>
      <p>
        In order to simplify the user experience, the calculator shows you only
        4 options:
      </p>
      <ol>
        <li>All races and combinations</li>
        <li>Non-hispanic white</li>
        <li>Pure black</li>
        <li>Pure asian</li>
      </ol>
    </div>

    <div class="heading-secondary u-margin-top-medium u-text-align-center">
      Exclude obese
    </div>
    <div class="box paragraph">
      <p>
        The exclude obese option filters out men that have a Body Mass Index
        (BMI) of 30 or higher.
      </p>
      <p>
        In the NHANES survey, BMI is calculated as weight in kilograms divided
        by height in meters squared.
      </p>
    </div>

    <div class="heading-secondary u-margin-top-medium u-text-align-center">
      Limitations
    </div>
    <div class="box paragraph">
      <p>
        How is the calculator able to merge the two statistical samples (ASEC
        and NHANES) to generate one reliable result?
      </p>
      <p>
        In an ideal world this calculator would process just one single big
        statistical sample of a survey that includes all the details we need:
        age, marital status, race, height, bmi and personal income.
        Unfortunately, this is not the case. The ASEC survey lacks height and
        bmi details, while the NHANES lacks details about personal income.
        Therefore, the Female Delusion Calculator has to run two different
        searches, one for income and one for body measures, then it combines the
        two results (both opportunely filtered by age, marital status and race)
        into one single percentage value.
      </p>
      <p>
        The final percentage is not far from reality but it's not entirely
        accurate because it doesn't take into account the correlations between
        income and height or weight. For example, there have been studies
        showing that taller people tend to be paid slightly more. So, by
        processing the two searches independently, the calculator might
        underestimate the number of tall high income individuals and
        overestimate the tall low income ones and so on. Still... I wouldn't
        expect the results to be way off, but keep it in mind and take the final
        number with a grain of salt.
      </p>
    </div>

    <!-- Ezoic - stats_bottom_of_page - bottom_of_page -->
    <div class="u-text-align-center" id="ezoic-pub-ad-placeholder-114"></div>
    <!-- End Ezoic - stats_bottom_of_page - bottom_of_page -->
  </div>
</template>

<script>
import ezoic from '@/utils/ezoic';

export default {
  name: 'Stats',

  mounted() {
    // update ezoic ads for this page
    ezoic.updateAds([114]);
  },
};
</script>

<style scoped lang="scss">
b {
  font-weight: $font-weight-paragraph-bold;
}

p {
  margin-bottom: 2rem;
}

ol {
  margin-bottom: 2rem;
  margin-left: 3rem;
}
</style>
