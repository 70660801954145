<template>
  <footer class="footer u-margin-top-big">
    <div class="container footer__content">
      <ul class="footerbar">
        <li>
          <router-link class="button-text-white" :to="{ name: 'Home' }">
            Home
          </router-link>
        </li>
        <li>
          <div class="footerbar__separator"></div>
        </li>
        <li>
          <router-link class="button-text-white" :to="{ name: 'Privacy' }">
            Privacy
          </router-link>
        </li>
        <li>
          <div class="footerbar__separator"></div>
        </li>
        <li>
          <router-link class="button-text-white" :to="{ name: 'Stats' }">
            Stats
          </router-link>
        </li>
        <li>
          <div class="footerbar__separator"></div>
        </li>
        <li>
          <router-link class="button-text-white" :to="{ name: 'About' }">
            About
          </router-link>
        </li>
        <li>
          <a href="https://twitter.com/GotStandardsBro" class="footerbar__icon">
            <img
              src="../assets/twitter.svg"
              alt="Twitter"
              class="footerbar__image"
            />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterMenu',
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  background-color: $color-grey-3;

  &__content {
    padding: 3rem 1rem;
  }
}

.footerbar {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__image {
    width: 2.4rem;
  }

  &__icon:link,
  &__icon:visited {
    margin-left: 2rem;
    transition: filter $interval-short ease-out;
    filter: saturate(0) brightness(150%);
  }

  &__icon:hover,
  &__icon:active {
    filter: saturate(100%);
  }

  &__separator {
    width: 1.2rem;
    height: 1.2rem;
    background-color: $color-accent;
    mask-image: url('../assets/separator.svg');
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
</style>
