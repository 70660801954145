<template>
  <div class="container">
    <div class="heading-primary u-margin-top-medium u-text-align-center">
      About
    </div>
    <div class="box paragraph">
      <p>How did I come up with the <b>Female Delusion Calculator</b> idea?</p>
      <p>
        During my "dating career" as a man living in North America I couldn't
        help noticing that women often have unrealistic expectations. They see
        themselves being passed around by those high quality men they feel
        entitled for, failing to realize those few men are in high demand. Time
        passes, options shrink, their standards don't change and they wonder why
        they are still single.
      </p>
      <p>
        The stats can prove there're not enough high quality men for every girl
        out there.
      </p>
      <p>
        The <b>Female Delusion Calculator</b> is a tool that can help women
        discern what is realistic from what is highly unlikely.
      </p>
      <p>Enjoy!</p>
    </div>
    <!-- Ezoic - about_bottom_of_page - bottom_of_page -->
    <div id="ezoic-pub-ad-placeholder-107"></div>
    <!-- End Ezoic - about_bottom_of_page - bottom_of_page -->
  </div>
</template>

<script>
import ezoic from '@/utils/ezoic';

export default {
  name: 'About',

  mounted() {
    // update ezoic ads for this page
    ezoic.updateAds([107]);
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 2rem;
}
</style>
